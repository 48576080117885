import { Button as BT, ConfigProvider } from 'antd';
import styled from 'styled-components';

const Button = ({ style, label, type, ...props }) => {
  return (
    <Button.Wrapper>
      <ConfigProvider
        theme={{
          components: {
            Button: {
              colorPrimaryHover:
                type === 'default' && style ? style.background : ''
            }
          }
        }}
      >
        <BT
          className={`${
            type === 'primary'
              ? 'primary_style'
              : type === 'secondary'
              ? 'secondary_style'
              : type === 'default'
              ? 'default_style'
              : type === 'delete'
              ? 'delete_style'
              : type === 'white'
              ? 'white_bg'
              : type === 'white_no_border'
              ? 'white_no_border_bg'
              : type === 'ghost'
              ? 'ghost_bg'
              : type === 'dark'
              ? 'dark'
              : type === 'light'
              ? 'light'
              : type === 'disabled'
              ? 'disabled'
              : ''
          }`}
          style={style && style}
          onClick={() => {}}
          {...props}
        >
          {label}
        </BT>
      </ConfigProvider>
    </Button.Wrapper>
  );
};

export default Button;

Button.Wrapper = styled.div`
  .primary_style {
    background: #2898a4 !important;
    color: white !important;
    border: none;
    font-weight: 600;
  }
  .delete_style {
    background: #ff6666 !important;
    color: #fff !important;
    border: none;
    font-weight: 600;
  }
  .secondary_style {
    background: #eaf8fa !important;
    color: #2898a4 !important;
    font-weight: 600;
    border: none;
  }
  .white_bg {
    background: #fff !important;
    border: 1px solid #2898a4;
    color: #2898a4 !important;
    font-weight: 600;
  }
  .white_no_border_bg {
    background: #fff !important;
    border: none;
    color: #2898a4 !important;
    font-weight: 600;
  }
  .dark {
    background: #545454 !important;
    border: none;
    color: #ffffff !important;
    font-weight: 600;
  }
  .light {
    background: #f2f2f2 !important;
    border: none;
    color: #545454 !important;
    font-weight: 600;
  }
  .disabled {
    background: #e1e1e1 !important;
    color: #878787 !important;
    font-weight: 600;
    border: none;
    cursor: not-allowed;
    .ghost_bg {
      background: transparent !important;
      color: #2898a4 !important;
      font-weight: 600;
      border: none !important;
    }
    .default_style {
      background: #e1e1e1 !important;
      color: #878787 !important;
      font-weight: 600;
      border: none;

      :hover {
        border: none !important;
        outline: none !important;
      }
    }
  }
`;
