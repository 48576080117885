import { Grid } from '@material-ui/core';
import { CustomText } from 'common/Reusables';
import { useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { publicPostHook } from 'hooks/mutations/postHook';
import { notification } from 'antd';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const ApplicationDocument = ({
  applicationForm,
  values,
  setDocumentIds,
  documentIds
}) => {
  const inputRef = useRef(null);
  const { id } = useParams();
  const [isDragging, setIsDragging] = useState(false);
  const [files, setFiles] = useState([]);
  const [api, contextHolder] = notification.useNotification();

  const handleFileUpload = (file, index) => {
    var form_data = new FormData();
    form_data.append('file', file);

    submitApplication.mutateAsync({
      route: `/recruits/${id}/jobpost/upload/`,
      payload: form_data
    });

    let list = [...files];
    list[index] = file.name;
    setFiles(list);
  };

  const openNotificationWithIcon = (type) => {
    api[type]({
      message: 'File Uploaded Successfully',
      description:
        'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Inventore, odit!',
      duration: 5
    });
  };

  const submitApplication = useMutation(publicPostHook, {
    onSuccess: (res) => {
      openNotificationWithIcon('success');
      setDocumentIds([...documentIds, res.id]);
    },
    onError: (error) => {}
  });

  return (
    <>
      {contextHolder}
      {(
        applicationForm?.application_form?.document ||
        values?.applicationform?.document
      )?.filter((item) => item.is_checked === true).length >= 1 && (
        <div className='border-bottom mt-5 mb-4'>
          <CustomText color='#545454' fontWeight='600' fontSize='18px'>
            Document
          </CustomText>
          <CustomText color='#545454' fontWeight='400' fontSize='12px'>
            All fields marked (*) are mandatory.
          </CustomText>
        </div>
      )}
      <Grid container spacing={1} className='mb-3'>
        {(
          applicationForm?.application_form?.document ||
          values?.applicationform?.document
        )
          .filter((item) => item.is_checked === true)
          .map((item, index) => (
            <Grid
              item
              sm={item.col}
              style={{
                paddingTop: '0px',
                paddingBottom: '0px',
                margin: '0px'
              }}
            >
              <p className='content'>
                {item.display_name} {item.required && '*'}
              </p>
              <Grid
                container
                style={{ margin: '2% 0px', background: '#F2F2F2' }}
                className={isDragging ? 'dragging' : ''}
                onDragOver={(event) => {
                  event.preventDefault();
                  setIsDragging(true);
                }}
                onDragLeave={(event) => {
                  event.preventDefault();
                  setIsDragging(false);
                }}
                onDrop={(event) => {
                  event.preventDefault();
                  setIsDragging(false);
                  handleFileUpload(event.dataTransfer.files);
                }}
              >
                <Grid item sm={12}>
                  <label className='select-file'>
                    <button onClick={() => inputRef.current.click()}>
                      <CloudUploadIcon color='#2898A4' />
                    </button>
                    <h1>Drag and drop here</h1>
                    <input
                      disabled
                      type='file'
                      accept='image/*,application/pdf'
                      style={{ display: 'none' }}
                      ref={inputRef}
                      onChange={(event) =>
                        handleFileUpload(event.target.files[0], index)
                      }
                    />
                  </label>
                </Grid>
              </Grid>
              <CustomText
                fontSize='12px'
                fontWeight='600'
                textAlign='center'
                color='#2898A4'
              >
                {files[index]}
              </CustomText>
            </Grid>
          ))}
      </Grid>
    </>
  );
};

export default ApplicationDocument;
