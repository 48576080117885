/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import BusinessRegistration from './BusinessRegistration';
import { createBusiness } from '../../api/common';
import { checkCompanyAddStatus } from '../../api/hr';
import { useQueryClient } from 'react-query';
import ProgressBar from '../../HR/Home/ProgressBar';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';
import BizedgeImage from '../../common/images/bizedgeRound.svg';
import { AuthFormContainer } from './styled.forms';
const tabHeight = '32px';

const useStyles = makeStyles({
  root: {
    '& .MuiSvgIcon-root': {
      // fontSize: '13px'
    },
    '& .MuiTab-textColorInherit.Mui-selected': {
      background: '#E0EEF0',
      borderRadius: '50px',
      minHeight: '20px',
      color: '#2898A4',
      fontWeight: 600,
      fontSize: '14px',
      fontStyle: 'normal',
      overflow: 'hidden'
    },
    '& .MuiTabs-indicator': {
      background: 'transparent !important'
    },
    '& .MuiTab-wrapper': {
      textTransform: 'capitalize'
    },
    '& .MuiTab-root': {
      // border: '1px solid purple',
      // width: '240px',
      overflow: 'hidden'
    },
    '& .MuiOutlinedInput-input': {
      background: '#FAFAFA'
    }
  },
  inputWrapper: {
    marginBottom: '1em'
  },
  divider: {
    margin: '2em 0'
  },
  plan: {
    border: '1.02058px solid #E1E1E1',
    borderRadius: '50px',
    width: '100%',
    margin: '0px auto 3em',
    overflow: 'hidden',
    background: '#FAFAFA',
    '& .MuiTabs-flexContainer': {
      display: 'block'
    }
  },
  tabsRoot: {
    minHeight: tabHeight,
    height: tabHeight
  },
  tabRoot: {
    minHeight: tabHeight,
    height: tabHeight
  },
  user: {
    '& .MuiOutlinedInput-input': {
      textAlign: 'center'
    }
  },
  summary: {
    border: '1px solid #E1E1E1',
    background: '#FAFAFA',
    padding: '10.5px 10px 10.5px 20px',
    borderRadius: '5px',
    margin: '2em auto',
    width: '100%',

    '& .MuiTypography-subtitle2': {
      color: '#878787',
      fontWeight: 400,
      fontSize: '14px'
    },
    '& .MuiTypography-subtitle1': {
      color: '#545454',
      fontWeight: 400,
      fontSize: '17px'
    }
  },
  recurring: {
    '& .MuiTypography-subtitle2': {
      color: '#878787',
      fontWeight: 400,
      fontSize: '14px'
    }
  },
  loaderWrapper: {
    background:
      'linear-gradient(180deg, rgba(255, 255, 255, 0) 56.25%, #D6F2F5 100%)',
    height: '100vh',
    width: '100%',
    '& .MuiTypography-subtitle1': {
      color: '#333333',
      fontWeight: 600,
      fontStyle: 'normal',
      fontSize: '28px',
      lineHeight: '138.55%'
    },
    '& .MuiTypography-subtitle2': {
      color: '#878787',
      fontWeight: 400,
      fontStyle: 'normal',
      fontSize: '18px',
      lineHeight: '175.55%',
      textAlign: 'center',
      maxWidth: '80%',
      margin: 'auto'
    }
  }
});

const BusinessRegistrationContainer = () => {
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();
  const classes = useStyles();
  const [completed, setCompleted] = useState(5);

  const [_, setIsError] = useState(false);
  useEffect(() => {
    if (isLoading) {
      setInterval(() => {
        setCompleted((prev) => (prev >= 95 ? prev : prev + 5));
      }, 5000);
    }
  }, [isLoading]);

  const handleSubmitBusiness = async (payload) => {
    const formData = new FormData();
    Object.keys(payload).forEach((key) => {
      formData.append(key, payload[key]);
    });

    setIsLoading(true);
    try {
      const response = await createBusiness(formData);
      if (response) {
        let formData = new FormData();
        formData.append('task_id', response?.task_id);
        const BusinessCreate = setInterval(async () => {
          const newBusiness = await checkCompanyAddStatus(formData);
          if (newBusiness.status === 'SUCCESS') {
            setIsLoading(false);
            clearInterval(BusinessCreate);
            queryClient.refetchQueries(['user']);
            queryClient.refetchQueries(['business-detail']);
            queryClient.refetchQueries(['current-billing']);
            localStorage.setItem(
              'business_domain',
              newBusiness?.data.business_id
            );
            window.location.href = '/dashboard';
          }
        }, 5000);
      }
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
    }
  };

  if (isLoading) {
    return (
      <Box className={classes.loaderWrapper}>
        <Box style={{ width: '60%', margin: '15em auto', textAlign: 'center' }}>
          <img
            src='https://res.cloudinary.com/dgny8sjrg/image/upload/v1638526892/Bizedge_Frontend_Assets/BizLoader_dzmb4i.gif'
            style={{ width: '140px', height: '140px', margin: 'auto' }}
            alt=''
          />
          <Typography variant='subtitle1'>
            Setting up your Business Account
          </Typography>
          <Typography variant='subtitle2'>
            This might take up to a minute, Please do not refresh the page or
            close this tab until the signup process has completed
          </Typography>
          <Box width='40%' margin='32px auto 0'>
            <ProgressBar
              bgcolor='#2898A4'
              height='6px'
              completed={completed}
              borderRadius={50}
            />
          </Box>
        </Box>
      </Box>
    );
  }
  return (
    <>
      <Helmet>
        <meta name='viewport' content='width=device-width, initial-scale=1' />
      </Helmet>
      <AuthFormContainer>
        <Box className='c-sideLeft'>
          <Box className='container'>
            <img
              src='https://res.cloudinary.com/dgny8sjrg/image/upload/v1638786088/Bizedge_Frontend_Assets/Bizedge_logo_axcwlg.svg'
              alt='logo'
              style={{ width: '135px', height: '100px', marginLeft: '12%' }}
              className='logo'
            />
            <Box className='info'>
              <img src={BizedgeImage} alt='' />
            </Box>
            <Typography variant='subtitle2' className='intro'>
              All In One for Ease and Effectiveness
            </Typography>
          </Box>
        </Box>
        <Box className={`${classes.root} c-sideRight`}>
          <Box className='c-sideRight-form'>
            <BusinessRegistration
              handleSubmit={handleSubmitBusiness}
              isLoading={isLoading}
            />
          </Box>
        </Box>
      </AuthFormContainer>
    </>
  );
};

export default BusinessRegistrationContainer;
