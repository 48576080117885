/* eslint-disable no-unused-vars */
import * as React from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { CustomTypographyStyle as style } from 'Account/Resuseable/typography';
import styled from 'styled-components';
import Typography from 'components/Typography';

const TabWrapper = styled(Typography)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const Label = ({ menu, active = false, ...props }) => {
  return (
    <TabWrapper bold={active} {...props}>
      {menu.label || menu.name}
    </TabWrapper>
  );
};

export default function BasicTabs({
  menus,
  notRouter = false,
  variant,
  size,
  value = null,
  onChange
}) {
  const history = useHistory();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentMenu, setCurrent] = useState(() =>
    notRouter ? value ?? menus?.[0]?.view : null
  );

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChange = (event, menu) => {
    // if (menu.hasDropDown) {
    //   handleOpen(event);
    //   return;
    // }
    if (!notRouter) {
      setCurrent(menu);
      history.push(menu?.route);
    } else {
      setCurrent(menu?.view);
      if (onChange) {
        onChange(menu);
      }
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          sx={{
            '& button:hover': {
              background: '#D6F2F5',
              borderRadius: '10px 10px 0 0'
            },
            '& button.Mui-selected': {
              color: '#2898A4',
              fontWeight: 600
            }
          }}
          scrollButtons={false}
          allowScrollButtonsMobile={false}
          {...(variant === 'scrollable' ? { variant } : {})}
          aria-label='scrollable force tabs example'
          {...(notRouter ? { value: currentMenu } : {})}
        >
          {menus.map((menu, index) => {
            const active = notRouter
              ? menu?.view?.includes(currentMenu)
              : menu?.route?.includes(location.search.split('&view')[0]);
            return (
              <Tab
                className='tab'
                label={<Label active={active} size={size} menu={menu} />}
                onClick={(event) => handleChange(event, menu)}
                key={index}
                {...(notRouter ? { value: menu.view } : {})}
                sx={[
                  style.sans,
                  style.f_16,
                  { marginRight: '1.5rem' },
                  (
                    notRouter
                      ? menu?.view?.includes(currentMenu)
                      : menu?.route?.includes(location.search.split('&view')[0])
                  )
                    ? {
                        fontWeight: 600,
                        color: '#2898A4',
                        opacity: 1,
                        background: '#EAF8FA',
                        borderBottom: '1px solid #2898A4',
                        borderRadius: '5px  5px 0px 0px'
                      }
                    : {
                        color: '#878787'
                      }
                ]}
              />
            );
          })}
        </Tabs>
      </Box>
    </Box>
  );
}
