import { useLocation } from 'react-router-dom';
import { Wrapper } from './styled.onboarding';
import LoginForm from './forms/Login';
import SignupForm from './forms/Signup';
import ForgotPasswordForm from './forms/ForgotPassword';
import ResetPasswordForm from './forms/ResetPassword';
import ResendConfirmationForm from './forms/ResendConfirmation';
import BusinessRegistrationContainer from './forms/BusinessRegistrationContainer';

const content = {
  '/login': LoginForm,
  '/signup': SignupForm,
  '/business-registration': BusinessRegistrationContainer,
  '/forgot-password': ForgotPasswordForm,
  '/account/password-reset': ResetPasswordForm,
  '/account/confirmation/new': ResendConfirmationForm
};

const getForm = (pathname) => content[pathname];

function Onboarding() {
  const { pathname } = useLocation();

  const Form = getForm(pathname) ? getForm(pathname) : content['/login'];

  return (
    <Wrapper
      style={{
        width: '100%',
        margin: '0px',
        height: '100vh'
      }}
    >
      {/* <LogoDiv>
        <Logo src={logo} />
      </LogoDiv>
      <DescriptionDiv>
        <IllustrationDiv>
          <Illustration src={getIllustration(pathname)} />
        </IllustrationDiv>
        <Text>One platform that caters for all your business needs</Text>
      </DescriptionDiv> */}
      <Form />
    </Wrapper>
  );
}

export default Onboarding;
